:root {
	--toastify-toast-width: 350px;
}

#root {
	display: flex;
	justify-content: center;
	width: 100vw;
	max-width: 100%;
	min-height: 100vh;
}

.container {
	width: 800px;
	max-width: 90%;
}

.header {
	text-align: center;
}

.header h1 {
	font-size: 2.3em;
	font-weight: 300;
	margin: 20px 0;
}

.bold-highlight {
	font-weight: 400;
}

.multicolor-highlight {
	background: linear-gradient(90deg, #cf0000, #0b6ec5);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.dropzone {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: #bcbcbc;
	border-style: dashed;
	background-color: #fafafa;
	color: #636363;
	text-align: center;
	outline: none;
	transition: border 0.24s ease-in-out;
}

.dropzone:focus {
	border-color: #2196f3;
}

.preview-container {
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-flow: wrap;
	margin-top: 16px;
}

.preview {
	display: inline-flex;
	width: auto;
	height: auto;
	box-sizing: border-box;
}

.preview-img {
	display: block;
	max-width: 100%;
	max-height: 100%;
	border-radius: 2px;
}

.palette-container {
	display: grid;
	grid-template-columns: auto auto auto auto auto;
	grid-column-gap: 10px;
	margin: 16px 0;
}

.palette-color {
	position: relative;
	aspect-ratio: 1/1;
	width: 100%;
	transition: 0.3s all ease;
	text-align: center;
}

.palette-color:before {
	content: "";
	transition: 0.3s all ease;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
}

.palette-color .color-overlay {
	color: white;
	line-height: 1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: 0.3s all ease;
}

@media screen and (min-width: 768px) {
	.palette-color:hover::before {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.palette-color:hover .color-overlay {
		transition: 0.3s all ease;
		opacity: 1;
	}

	.palette-color .color-overlay span {
		margin: 10px 0;
	}

	.palette-color .color-overlay span:hover {
		cursor: pointer;
	}
}

@media screen and (max-width: 768px) {
	.color-hex {
		display: block;
		width: 100%;
		height: 100%;
	}

	.color-rgb {
		display: none;
	}
}

.privacy-policy-btn {
	background-color: transparent;
	border: none;
}

.privacy-policy-btn:hover {
	cursor: pointer;
}

.privacy-policy-container-overlay .popup-content {
	max-height: 75vh;
	padding: 10px 20px;
	border-radius: 20px;
	overflow-y: auto;
}

.footer {
	padding: 2px 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Toastify__toast-container {
	text-align: center;
}
